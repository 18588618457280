/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Text, Card, Flex, Link } from "rebass";
import { Image } from "../images/images";

import MainContainer from "../../ui-components/container/container";

interface CardProps {
  data: {
    title: string;
    cards: [
      {
        slug: string;
        title: string;
        subtitle: string;
        image: any;
        number: boolean;
      }
    ];
  };
  maxNumber: number;
  id: string;
}

const Cards = ({ data: { title, cards }, maxNumber, id }: CardProps) => {
  return (
    <MainContainer my={4} id={id}>
      <Text as="h2" variant="subheading">{title}</Text>
      <Flex justifyContent="space-between" flexWrap="wrap">
        {cards
          .slice(0, maxNumber)
          .map(({ slug, title, subtitle, image, number }, i) => {
            return (
              <Link href={slug} key={title} variant="cardProjectLink">
                <Card variant="cardImage">
                  <Image src={image} className="image--hover-card" />
                  {/* <Image variant="imageCard" src={image.file.url} alt={title} /> */}
                  <Flex flexWrap="wrap" p={[2]}>
                    <Text as="h3" variant="cardTitle">
                      {title}
                    </Text>
                    <Text as="p" variant="cardText">
                      {subtitle}
                    </Text>
                  </Flex>
                </Card>
              </Link>
            );
          })}
      </Flex>
    </MainContainer>
  );
};

export default Cards;
