/** @jsx jsx */
import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { jsx } from "theme-ui";
import { Text, Flex, Link, Card, Box } from "rebass";
import { Image } from "../images/images";

import MainContainer from "../../ui-components/container/container";

interface Props {
  title?: boolean;
  limitedPosts?: boolean;
}
const Cards = ({title = true, limitedPosts = false}: Props) => {
  const {
    allContentfulBlogPost: { edges }
  } = useStaticQuery(
    graphql`
      query {
        allContentfulBlogPost(sort: {order: DESC, fields: createdAt}) {
          edges {
            node {
              slug
              tags
              metaDescription {
                metaDescription
              }
              metaTitle
              socialMediaImage {
                ...ContentfulAsset
              }
              updatedAt
            }
          }
        }
      }
    `
  );
  let numberOfPosts = 100;

  if (limitedPosts) {
    numberOfPosts = 3;
  }

  return (
    <MainContainer my={4}>
      {title && (
        <Text as="h2" variant="subheading">
          Blog Posts
        </Text>
      )}
      <Flex justifyContent="space-between" flexWrap="wrap">
        {edges.slice(0, numberOfPosts).map(({ node: {
            // @ts-ignore
            slug,
            // @ts-ignore
            tags,
            // @ts-ignore
            metaTitle,
            // @ts-ignore
            metaDescription,
            // @ts-ignore
            socialMediaImage,
            // @ts-ignore
            updatedAt } }): any => {
          return (
            <Box key={metaTitle} variant="cardBlogContainer">
              <Link href={slug} variant="cardBlogLink">
                <Card variant="cardImage">
                  <Image src={socialMediaImage} className="image--blog-post" />
                  <Flex flexWrap="wrap" p={[2]}>
                    <Text as="h3" variant="cardTitle">
                      {metaTitle}
                    </Text>
                    <Box>
                      {tags &&
                        tags.splice(0, 3).map((tag: string) => (
                          <Box
                            key={tag}
                            sx={{
                              display: "inline-block",
                              color: "#767676",
                              bg: "#E2E2E2",
                              px: 2,
                              py: 1,
                              borderRadius: 4,
                              mr: 2,
                              my: 2,
                              fontSize: "10px"
                            }}
                          >
                            {tag}
                          </Box>
                        ))}
                    </Box>
                    <Text as="p" variant="cardText">
                      {metaDescription.metaDescription}
                    </Text>
                  </Flex>
                </Card>
              </Link>
            </Box>
          );
        })}
      </Flex>
    </MainContainer>
  );
};

export default Cards;
