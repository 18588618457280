/** @jsx jsx */
import { jsx } from "theme-ui";
import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../ui-components/layout";
import FullScreenImage from "../components/full-screen-image/full-screen-image";
import HoverCard from "../components/hover-card/hover-card";
import SEO from "../components/seo/seo";
import BlogPosts from "../components/blog-posts/blog-posts";
import Prefooter from "../components/prefooter/prefooter";

interface BlogPageProps {
  data: {
    site: {
      siteMetadata: {
        name: string;
        tagline: string;
        githubLink: string;
      };
    };
    allContentfulBlogHub: {
      edges: {
        node: {
          id: number;
          metaTitle: string;
          metaDescription: {
            metaDescription: string;
          };
          socialMediaImage: {
            file: {
              url: string;
            };
          };
          language: [];
          title: string;
          hero: any;
          recentProjects: {
            title: string;
            cards: {
              slug: string;
              title: string;
              subtitle: string;
              image: {
                file: {
                  url: string;
                };
              };
              number: boolean;
            };
          };
          prefooter: {
            title: string;
            button: {
              slug: string;
              text: string;
              color: string;
              isButton: boolean;
            };
          };
        };
      };
    };
  };
}

export const blogPageQuery = graphql`
  query BlogPageQuery {
    allContentfulBlogHub(limit: 1) {
      edges {
        node {
          id
          metaTitle
          metaDescription {
            metaDescription
          }
          socialMediaImage {
            file {
              url
            }
          }
          language
          title
          hero {
            ...FullScreenImageSection
          }
          recentProjects {
            title
            cards {
              slug
              title
              subtitle
              image {
                ...ContentfulAsset
              }
              number
            }
          }
          prefooter {
            ...Prefooter
          }
        }
      }
    }
  }
`;

export default class BlogHub extends React.Component<BlogPageProps, {}> {
  public render() {
    const {
      // @ts-ignore
      node: {
        metaTitle,
        metaDescription,
        socialMediaImage,
        slug,
        language,
        hero,
        recentProjects,
        prefooter
      }
    // @ts-ignore
    } = this.props.data.allContentfulBlogHub.edges[0] as BlogPageProps;
    return (
      <Layout>
        <SEO
          title={metaTitle}
          description={metaDescription.metaDescription}
          image={socialMediaImage.file.url}
          pathname={slug}
          lang={language}
        />
        <main>
          <FullScreenImage data={hero} />
          <BlogPosts title={false} />
          <HoverCard data={recentProjects} maxNumber={4} id="work" />
          <Prefooter data={prefooter} />
        </main>
      </Layout>
    );
  }
}
